'use client';

import dynamic from 'next/dynamic';

import { getGridCardWidth } from 'components/Cards/helpers/getCardGridWidth';
import { IIntroText } from 'components/IntroText';
import { NextLink } from 'components/NextLink';
import { useEffect, useState } from 'react';
import { buildAlgoliaQuery } from 'server/repository/algolia/builder/Builder';
import getSearchClient from 'server/repository/algolia/client';
import transformAlgoliaRecipes from 'server/repository/algolia/transformer/Recipies/AlgoliaRecipesTransformer';
import { DictionaryService } from 'service/dictionary/dictionaryService';
import { LogService } from 'service/log/logService';
import { usePrevious } from '../../hooks/usePrevious';
import { SortItemType, useSort } from '../../hooks/useSort';
import { FilterTopic } from '../FilterMenu/types';

import type { IBreadcrumbTitles } from 'components/Breadcrumbs';
import { ISuggestedData } from 'components/Cards/types';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import type { ISearchOverlay } from 'components/HeaderNavigation/components/MobileNavigation';
import type { ISocialMediaComponent } from 'components/SocialMedia/types';
import type { ICardItem } from 'components/Subcategory';
import type { DictionaryItem } from 'service/dictionary/dictionaryService';
import { RelatedDataService } from 'service/relatedData/relatedDataService';
import { getSortedIndexName } from 'service/search/getSortedIndexName';
import { TrackingService } from 'service/tracking/trackingService';
import { COLORS } from 'styles/constants';
import TemplateMapper from 'templates/TemplateMapper';
import type { IGlobalMetaData } from 'types/GlobalMetadata';
import type { IHeroCarouselProps } from 'types/LandingPageData';
import type { IMetaData } from 'types/MetaData';

const Grid = dynamic(() => import('components/Grid').then((m: any) => m.Grid), {
  ssr: true,
}) as any;

const GridItem = dynamic(() => import('components/Grid').then((m: any) => m.GridItem), {
  ssr: true,
}) as any;

const GridRow = dynamic(() => import('components/Grid').then((m: any) => m.GridRow), {
  ssr: true,
}) as any;

const LiquidButton = dynamic(
  () => import('components/LiquidButton').then((m: any) => m.LiquidButton),
  { ssr: true },
) as any;

const Card = dynamic(() => import('components/Cards').then((m: any) => m.Card), {
  ssr: true,
}) as any;

const FilterMenu = dynamic(() => import('components/FilterMenu').then((m: any) => m.FilterMenu), {
  ssr: true,
}) as any;

const IntroText = dynamic(() => import('components/IntroText').then((m) => m.IntroText), {
  ssr: true,
});

const Breadcrumbs = dynamic(
  () => import('components/Breadcrumbs').then((m: any) => m.Breadcrumbs),
  { ssr: true },
) as any;

const SocialMedia = dynamic(() => import('components/SocialMedia').then((m) => m.SocialMedia), {
  ssr: true,
});

const SliderCarousel = dynamic(
  () => import('components/SliderCarousel').then((m) => m.SliderCarousel),
  { ssr: true },
);

const Subcategory = dynamic(
  () => import('components/Subcategory').then((m: any) => m.Subcategory),
  { ssr: true },
) as any;

const Spacer = dynamic(() => import('components/Spacer').then((m) => m.Spacer), {
  ssr: true,
});

const SortAndFilter = dynamic(
  () => import('components/SortAndFilter').then((m: any) => m.SortAndFilter),
  { ssr: true },
) as any;

const Title = dynamic(() => import('components/Typography').then((m: any) => m.Title), {
  ssr: true,
}) as any;

interface Category {
  recipeCategories: {
    slug: string;
    id: string;
    name: string;
    metaData: IMetaData;
    heroCarouselComponent: IHeroCarouselProps;
    introText: IIntroText;
    recipeSubcategorySuper: string;
    recipeSubcategoryTitle: string;
    recipeSubcategories?: {
      id: string;
      slug: string;
      productCategory: {
        categoryName: string;
        id: string;
      };
    }[];
  }[];
  recipesText: {
    value: string;
  };
  learnMoreText: {
    value: string;
  };
}

function ClientRecipesCategorySlugPage({
  locale,
  recipeEnv,
  category,
  dynamicPageComponents,
  followUsData,
  breadcrumbsCMSData,
  searchOverlayData,
  recipes,
  numberOfRecipes,
  requiredHitsPerPage,
  refinementList,
  difficultyCopy,
  recipeCopy,
  globalMetadata,
  searchItemLabels,
  searchPageLabels,
  host,
}: {
  locale: string;
  recipeEnv: string;
  category: Category;
  dynamicPageComponents: any;
  followUsData: ISocialMediaComponent;
  breadcrumbsCMSData: IBreadcrumbTitles;
  searchOverlayData: ISearchOverlay;
  recipes: any[];
  numberOfRecipes: number;
  requiredHitsPerPage: number;
  difficultyCopy: Record<string, string>;
  recipeCopy: DictionaryItem[];
  refinementList: { [name: string]: string | string[] };
  globalMetadata: IGlobalMetaData;
  searchItemLabels: Record<string, string>;
  searchPageLabels: Record<string, string>;
  host: string;
}) {
  const { pathTranslations } = useGlobalContext();
  const { getAvailableSortMethods } = useSort(SortItemType.ANY);
  const [currentSortMethod, setCurrentSortMethod] = useState<string>(
    () => getAvailableSortMethods()[0],
  );
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<string[][]>();
  const [totalResult, setTotalResult] = useState<number>(numberOfRecipes);
  const previousAppliedFilters = usePrevious(appliedFilters);
  const [numberOfRecipesLeft, setNumberOfRecipesLeft] = useState<number>(
    numberOfRecipes - requiredHitsPerPage,
  );
  const [errorMessage, setErrorMessage] = useState<string>();
  const [page, setPage] = useState<number>(0);
  let totalCardWidth = 0;
  let isOdd = true;
  const watchVideoLabel = DictionaryService.getCopy('see_video', recipeCopy);

  const applyFilters = (newFilters: FilterTopic[]): void => {
    const selectedFacets: string[][] = newFilters.map((nf) =>
      nf.options.reduce(
        (topicResults, option) => (option.selected ? [...topicResults, option.key] : topicResults),
        [] as string[],
      ),
    );
    const filters = selectedFacets.reduce(
      (facets: string[][], current: string[]) => (!current.length ? facets : [...facets, current]),
      [] as string[][],
    );
    setAppliedFilters(filters);
  };
  const heroCarouselData = category?.recipeCategories[0]?.heroCarouselComponent;
  const introData = category?.recipeCategories[0]?.introText;
  const categoryData = category?.recipeCategories[0] || {};
  const subcategoryData = {
    subHeading: categoryData?.recipeSubcategorySuper,
    heading: categoryData?.recipeSubcategoryTitle,
  };
  const [recipeResultsCards, setRecipeResultsCards] = useState<ISuggestedData[]>(
    RelatedDataService.getRelatedData(recipes, pathTranslations, 'recipe'),
  );
  // TODO: loading and error copy should come from GraphCMS, either  dictionary or page template.
  const [loading, setLoading] = useState<boolean>(false);
  const previousSort = usePrevious(currentSortMethod);
  const [availableFilters, setAvailableFilters] = useState<FilterTopic[]>([]);
  const breadcrumbsCategories = [
    {
      slug: pathTranslations.recipes,
      displayName: breadcrumbsCMSData?.recipesText?.value,
    },
  ];
  const subcategoryCardsData = categoryData?.recipeSubcategories?.map((subCategory: any) => {
    const amount = subCategory?.totalRecipes;
    // returns card data
    return {
      productsAmount: `${amount || 0} ${category?.recipesText?.value || 'recipes'}`,
      categoryId: subCategory?.id,
      category: categoryData.name,
      id: subCategory?.id,
      images: {
        desktop: subCategory?.promoImage?.asset?.[0]?.desktop,
        mobile: subCategory?.promoImage?.asset?.[0]?.mobile,
        mimeType: subCategory?.promoImage?.asset?.[0]?.mimeType,
      },
      title: subCategory?.recipeSubCategoryName,
      link: {
        url: `${pathTranslations.recipesSubCategory}${categoryData?.slug}/${subCategory?.slug}`,
        displayName: '',
      },
    };
  });

  const [searchOverlayDataExtended, setSearchOverlayDataExtended] = useState(searchOverlayData);

  useEffect(() => {
    if (!searchOverlayDataExtended.searchClient) {
      const algoliaClient = getSearchClient();
      setSearchOverlayDataExtended({
        ...searchOverlayDataExtended,
        searchClient: algoliaClient,
      });
    }
  }, [searchOverlayData]);

  const updateFilters = (facets: Record<string, Record<string, number>>): FilterTopic[] => {
    const difficultLabels: Record<string, string> = {
      0: searchItemLabels?.easy || 'Easy',
      1: searchItemLabels?.intermediate || 'Intermediate',
      2: searchItemLabels?.tough || 'Tough',
    };
    // returns updated filters
    return Object.keys(facets).map((key: string) => ({
      key,
      label: searchItemLabels?.[`filter_${key}`] || key,
      options: Object.keys(facets[key]).map((opt: string) => ({
        key: `${key}:${opt}`,
        label:
          key === 'difficulty' ? difficultLabels[opt] : searchItemLabels?.[`${key}:${opt}`] || opt,
        selected: appliedFilters?.some(
          (appliedFilter) => appliedFilter.indexOf(`${key}:${opt}`) > -1,
        ),
        itemCount: facets[key][opt],
      })),
      type: key === 'rating_effective' ? 'rating' : 'text',
    })) as FilterTopic[];
  };
  useEffect(() => {
    const loadRecipes = async () => {
      setLoading(true);
      try {
        const indexName = getSortedIndexName('RECIPES', recipeEnv, locale, currentSortMethod);
        const index = getSearchClient().initIndex(indexName);
        const response = await LogService.logDuration(
          'AlgoliaClient',
          'GetSortedRecipes',
          index.search('', {
            filters: buildAlgoliaQuery(refinementList),
            facetFilters: appliedFilters,
            page,
            hitsPerPage: requiredHitsPerPage,
            facets: [
              'menu_types',
              'preparation_time',
              'servings',
              'difficulty',
              'diet_types',
              'seasons.title',
              'occasions',
              'rating_effective',
              'preparation_types',
              'keywords',
              'category',
            ],
          }),
        );
        if (currentSortMethod !== previousSort || appliedFilters !== previousAppliedFilters) {
          setPage(0);
        }
        setTotalResult(response.nbHits);
        const indexFilters = updateFilters(response.facets || {});
        setAvailableFilters(indexFilters);
        const transformedRecipes = transformAlgoliaRecipes(response.hits);
        const parsedRecipes = RelatedDataService.getRelatedData(
          transformedRecipes,
          pathTranslations,
          'recipe',
          searchPageLabels?.see_recipe,
        );
        if (
          page === 0 ||
          currentSortMethod !== previousSort ||
          appliedFilters !== previousAppliedFilters
        ) {
          setRecipeResultsCards(parsedRecipes);
          setTotalResult(response.nbHits);
          setNumberOfRecipesLeft(totalResult - requiredHitsPerPage);
        } else {
          setRecipeResultsCards([...recipeResultsCards, ...parsedRecipes]);
        }
      } catch (err) {
        setErrorMessage('Unable to get more recipes momentarily');
        // eslint-disable-next-line no-console
        console.error('Failed to fetch recipes', err);
      } finally {
        setLoading(false);
      }
    };
    loadRecipes();
  }, [page, currentSortMethod, appliedFilters, totalResult]);
  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
    setNumberOfRecipesLeft((n) => n - requiredHitsPerPage);
  };

  const recipefilterData = {
    id: 'recipe-category-filter-menu',
    isOpen: showFilters,
    title: searchItemLabels?.filters,
    filters: availableFilters,
    totalResults: totalResult,
    onClose: () => {
      setShowFilters(false);
    },
    onApplyFilters: (newFilters: FilterTopic[]) => applyFilters(newFilters),
    labels: searchItemLabels,
  };
  const sortFilterData = {
    id: 'recipe-category',
    labels: searchItemLabels,
    options: getAvailableSortMethods().map((m) => ({
      label: searchItemLabels?.[`sort_${m}`] || m,
      value: m,
    })),
    selectedSort: currentSortMethod,
    currentCount: totalResult,
    currentFiltersCount: 99,
    onSort: (sm: string) => setCurrentSortMethod(sm),
    onFilter: () => setShowFilters(true),
  };

  useEffect(() => {
    TrackingService.contentGroups({ cg1: 'recipe categories', cg2: categoryData?.name || '' });
  }, []);

  let finalFilterCategoryText = '';
  let finalFilterValueText = '';
  const finaldata: any = [];
  const appliedFilterCategory = appliedFilters?.map((item) => {
    const filterCategoryData = item[0].split(':')[0];
    return filterCategoryData;
  });
  const appliedFiltersCategory = appliedFilters?.map((item) => {
    const filterCategoryValue = item.map((appliedCategory) => appliedCategory.split(':')[1]);
    filterCategoryValue.forEach((data) => {
      finaldata.push(data);
    });
    return finaldata;
  });
  appliedFilterCategory?.map((appliedCategory: string) => {
    finalFilterCategoryText += `${appliedCategory},`;
  });
  appliedFiltersCategory?.[0]?.map((items: string) => {
    finalFilterValueText += `${items},`;
  });

  /**
   * Used to fire the event for recipe cards in the Subcategory component
   */
  const subcategoryComponentClickContainer = (data: any) => {
    TrackingService.subcategoryComponentRecipeClick(data.category || 'na', data.title || 'na');
  };

  /**
   * Used to fire the event for recipe cards in grid of recipes
   * which can appear on this landing page
   */
  const seeRecipeClickContainer = (data: any) => {
    TrackingService.seeRecipeClick(
      category?.recipeCategories?.[0]?.name || 'na',
      data?.category || 'na',
      data?.title || 'na',
      data?.id || 0,
      difficultyCopy[data?.information?.difficulty] || 'na',
      data?.information?.rating || 'na',
      data?.information?.preparationTime || 'na',
      finalFilterCategoryText || 'na',
      finalFilterValueText || 'na',
      currentSortMethod || 'na',
    );
  };
  const GridCard = (recipe: any, index: number, totalCards: number) => {
    const columnWidth = getGridCardWidth(totalCards, index);
    totalCardWidth += columnWidth;
    const prevOdd = isOdd;
    if (totalCardWidth % 32 === 0) {
      isOdd = !isOdd;
    }
    return (
      <Card
        data={recipe}
        cardType="medium"
        LinkComponent={NextLink}
        link={recipe.link}
        height="500px"
        copyDictionary={difficultyCopy}
        showInfo
        trackingEvent={seeRecipeClickContainer}
        position={index + 1}
        isOdd={prevOdd}
      />
    );
  };
  return (
    <>
      <FilterMenu {...recipefilterData} />
      <Breadcrumbs
        titles={breadcrumbsCMSData}
        pageName={category?.recipeCategories[0]?.name}
        categories={breadcrumbsCategories}
      />
      {!heroCarouselData && <Spacer />}
      <div className="mx-auto flex flex-col items-center mb-40 relative overflow-x-hidden">
        {heroCarouselData && (
          <>
            <SliderCarousel
              bannerMasterbrands={heroCarouselData?.bannerMasterbrands}
              banners={heroCarouselData?.banners}
              isForBanners
              type="carousel"
              watchVideoLabel={watchVideoLabel}
            />
            <Spacer height={50} />
          </>
        )}
        {introData && !subcategoryCardsData?.length && (
          <>
            <IntroText
              super={introData.super ?? ''}
              title={introData.title ?? ''}
              description={introData.description ?? ''}
            />
            <Spacer />
          </>
        )}
        {subcategoryCardsData && !!subcategoryCardsData?.length && (
          <Subcategory
            titles={subcategoryData}
            cardData={subcategoryCardsData as ICardItem[]}
            subcategoryCardVariation="object"
            trackingEvent={subcategoryComponentClickContainer}
          />
        )}
        {recipeResultsCards.length > 0 && (
          <>
            <div className="pt-20" />
            <div itemScope itemType="https://schema.org/ItemList" style={{ display: 'none' }}>
              {recipeResultsCards?.map((recipe, index) => (
                <div
                  key={recipe.id}
                  itemProp="itemListElement"
                  itemScope
                  itemType="https://schema.org/ListItem"
                >
                  <span itemProp="position">{index + 1}</span>
                  <span itemProp="url">{`https://${host}${recipe.link?.url}`}</span>
                </div>
              ))}
            </div>
            <Grid>
              <GridRow columns={16}>
                <GridItem colSpan={16}>
                  <div className="flex flex-col items-center justify-center md:justify-between md:flex-row pb-10 w-full">
                    <div className="flex flex-grow justify-center w-full md:mr-14 md:justify-start mb-3 md:mb-0">
                      <Title tag="h2" type="sm">
                        {category.recipeCategories?.[0].recipeSubcategoryTitle}
                      </Title>
                    </div>
                    <SortAndFilter {...sortFilterData} />
                  </div>
                </GridItem>
              </GridRow>
              <GridRow columns={16} tabletBreakpoint>
                {recipeResultsCards.map((recipe: any, index: number) => (
                  <GridItem
                    key={recipe.id}
                    colSpan={getGridCardWidth(recipeResultsCards.length, index)}
                  >
                    {GridCard(recipe, index, recipeResultsCards.length)}
                  </GridItem>
                ))}
              </GridRow>
              {loading && (
                <GridRow>
                  <p>loading...</p>
                </GridRow>
              )}
              {errorMessage && (
                <GridRow>
                  <p>{errorMessage}</p>
                </GridRow>
              )}
              {numberOfRecipesLeft > 0 && (
                <GridRow>
                  <button
                    type="button"
                    onClick={() => {
                      loadMore();
                    }}
                    disabled={loading}
                  >
                    <LiquidButton
                      text={searchPageLabels?.load_more || 'Load More'}
                      height={50}
                      width={190}
                      color={COLORS.primary}
                      textColor={COLORS.white}
                    />
                  </button>
                </GridRow>
              )}
            </Grid>
          </>
        )}
      </div>
      {dynamicPageComponents && (
        <TemplateMapper
          components={dynamicPageComponents}
          copy={[]}
          globalMetadata={globalMetadata}
          recipeCopy={recipeCopy}
          difficultyCopy={difficultyCopy}
          searchItemLabels={searchItemLabels}
          searchData={searchOverlayDataExtended}
        />
      )}
      {followUsData && (
        <SocialMedia
          id={followUsData.id}
          super={followUsData.super}
          title={followUsData.title}
          socialMedia={followUsData.socialMedia}
          middlegroundImage={followUsData.middlegroundImage}
          foregroundImage={followUsData.foregroundImage}
        />
      )}
    </>
  );
}

export default ClientRecipesCategorySlugPage;
